<template>
  <div class="group">
    <div class="left">
      <div class="title">现阶段项目</div>
      <div class="items" v-loading="phaseLoading">
        <div v-if="!phaseLoading && !phaseList.length">暂无数据</div>
        <div v-else>
          <div
            class="item"
            :class="selectIndex == item.id ? 'checked' : ''"
            v-for="(item, index) in phaseList"
            :key="index"
            @click="
              selectIndex = item.id;
              getProjectPhaseList(item);
            "
          >
            <div>项目：[ {{ item.name }} ]</div>
            <div v-if="type == 'enterprise'">
              <span v-if="item.painter_user_id && !item.ph_state">
                画手：[ {{ item.painter_user_id }} ]
              </span>
            </div>
            <span class="state">{{ item.state_text }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="title">
        {{ projectPhaseTitle }}
      </div>
      <div class="table">
        <table width="100%">
          <tr class="header">
            <td></td>
            <td>板块</td>
            <td>
              <!-- 画手 -->
              <span v-if="type == 'painter'">企业</span>
              <!-- 企业 -->
              <span v-else-if="type == 'enterprise'">画手</span>
            </td>
            <td>进度</td>
            <td>上次提交时间</td>
            <td>状态</td>
          </tr>
          <tr
            class="cell"
            v-for="(item, index) in projectPhaseList"
            :key="index"
          >
            <td>{{ item.id }}</td>
            <td>{{ item.phase }}</td>
            <td>
              <!-- 画手 -->
              <span v-if="type == 'painter'">
                {{ item.enterprise_nickname }}
              </span>
              <!-- 企业 -->
              <span v-if="type == 'enterprise'">
                {{ item.painter_nickname }}
              </span>
            </td>
            <td>{{ item.state_text }}</td>
            <td>{{ item.createtime_text }}</td>
            <!-- 画手 -->
            <td class="release" v-if="type == 'painter'">
              <span
                @click="$emit('releaseCLick', project)"
                v-if="project.state <= 1 && item.state == 1"
              >
                发布测试图>
              </span>
              <!-- <span
                @click="
                  $emit('releaseCLick', { type: 'router', data: project })
                "
                v-if="project.state != 3 && item.state == 4"
              >
                上传作品文件>
              </span> -->
            </td>
            <!-- 企业 -->
            <td class="release" v-if="type == 'enterprise'">
              <span
                @click="
                  $emit('releaseCLick', { type: 'router', data: project })
                "
                v-if="project.state != 3 && item.state == 3 && !checkComplete"
              >
                发布需求
              </span>
              <span
                @click="$emit('complete', project)"
                v-if="project.state != 3 && item.state == 6 && !checkComplete"
              >
                完成此单
              </span>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    type: String,
  },
  data() {
    return {
      project: {},
      selectIndex: 0,
      phaseList: [],
      projectPhaseTitle: "",
      projectPhaseList: [],
      phaseLoading: true,
    };
  },
  computed: {
    checkComplete: function () {
      return this.projectPhaseList.find((item) => item.state == 7);
    },
  },
  mounted() {
    this.getPhaseList();
  },
  methods: {
    getPhaseList(project = "") {
      let data = { userType: this.type };
      this.$api.project.phase_list(data).then((res) => {
        this.phaseList = res.data;
        this.phaseLoading = false;
        if (res.data.length) {
          this.getProjectPhaseList(project ? project : res.data[0]);
        }
      });
    },
    getProjectPhaseList(item) {
      this.projectPhaseList = [];
      this.project = item;
      this.selectIndex = item.id;
      this.projectPhaseTitle =
        "项目：[ " +
        item.name +
        " ]" +
        (this.type == "enterprise" && item.painter_user_id
          ? "；画手：[ " + item.painter_user_id + " ]"
          : "");
      let data = {
        id: item.id,
        user_id: item.painter_user_id ? item.painter_user_id : item.user_id,
      };
      this.$api.projectPhase.list(data).then((res) => {
        for (let i = 0; i < res.data.length; i++) {
          if (res.data[i].state == 4) {
            res.data[i].state_text = "提交需求文案";
            break;
          }
        }
        this.projectPhaseList = res.data;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.group {
  display: flex;
  .left {
    margin-right: 2vw;
    .title {
      font-size: 1.4vw;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #73dcfe;
      margin-bottom: 20px;
    }
    .items {
      max-height: 30vw;
      overflow: auto;
      &::-webkit-scrollbar {
        display: none;
      }
      .checked {
        color: #73dcfe !important;
      }
      .item {
        background: url("../assets/images/order_management/left-bg.png") no-repeat;
        background-size: 100% 100%;
        font-size: 1vw;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
        padding: 15px 21px;
        margin-bottom: 5px;
        text-align: left;
        cursor: pointer;
        .state {
          color: red;
        }
      }
    }
  }
  .right {
    flex: 1;
    background: url("../assets/images/order_management/table-bg.png") no-repeat;
    background-size: 100% 100%;
    padding: 25px 29px;
    .title {
      font-size: 1.2vw;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #73dcfe;
      text-align: left;
      margin-bottom: 10px;
    }
    .table {
      color: #ffffff;
      max-height: 30vw;
      overflow: auto;
      &::-webkit-scrollbar {
        display: none;
      }
      .header {
        font-size: 1.2vw;
        font-family: Source Han Sans CN;
        font-weight: 400;
      }
      .cell td {
        padding-top: 21px;
      }
      .release {
        color: #fbed53;
        cursor: pointer;
      }
    }
  }
}
</style>
