<template>
  <div>
    <TaskOrder
      v-show="!orderManagementShow"
      @releaseCLick="releaseCLick"
      type="painter"
      ref="TaskOrder"
    ></TaskOrder>
    <div class="order-management" v-show="orderManagementShow">
      <OrderView class="order-view" :data="OrderViewData1"></OrderView>
      <OrderView
        class="order-view"
        :data="OrderViewData2"
        @buttonImgClick="buttonImgClick"
        ref="orderView2"
      ></OrderView>
      <div class="back" @click="orderManagementShow = false">返回</div>
    </div>
  </div>
</template>

<script>
var moment = require("moment");
import OrderView from "../../components/OrderView.vue";
import TaskOrder from "../../components/TaskOrder.vue";
export default {
  components: {
    OrderView,
    TaskOrder,
  },
  data() {
    return {
      project: {},
      orderManagementShow: false,
      OrderViewData1: {
        type: "",
        top: [],
        center: {},
        buttonImg: require("../../assets/images/order_management/have-order.png"),
      },
      OrderViewData2: {
        type: "upload",
        top: [
          { value: `发布时间：${moment().format("YYYY/MM/DD")}`, type: "text" },
          { value: require("../../assets/images/order_management/testimg.png"), type: "img" },
        ],
        uploadText: "上传测试图",
        buttonImg: require("../../assets/images/order_management/submit.png"),
      },
    };
  },
  mounted() {
    let { project, orderManagementShow } = this.$route.params;
    if (project && orderManagementShow) {
      this.project = project;
      this.orderManagementShow = orderManagementShow;
      this.setOrderView();
    }
  },
  methods: {
    releaseCLick(project) {
      let { type, data } = project;
      if (type == 'router') {
        let params = {
          project_id: data.id,
          user_id: data.painter_user_id,
        };
        this.$api.projectTestimg.detail(params).then((res) => {
          if (type == "router") {
            this.$router.push({
              name: "/painter/communication",
              params: { type: "centerTypeOfImg", data: res.data, uploadText: '上传作品文件' },
            });
          }
        });
      } else {
        this.project = project;
        this.orderManagementShow = true;
        this.setOrderView();
      }
    },
    // 设置top
    setOrderView() {
      this.OrderViewData1.top = [
        { value: `发布时间：${this.project.createtime_text}`, type: "text" },
        { value: require("../../assets/images/order_management/test.png"), type: "img" },
        { value: `状态：${this.project.state_text}`, type: "text" },
      ];
      this.OrderViewData1.center = {
        type: "text",
        data: this.project,
      };
    },
    buttonImgClick() {
      let image = this.$refs.orderView2.image;
      if (!image) {
        this.$message({
          message: "请先上传测试图",
          type: "warning",
        });
        return;
      }
      let data = {
        project_id: this.project.id,
        image,
      };
      this.$api.projectTestimg.add(data).then(() => {
        this.orderManagementShow = false;
        this.$refs.TaskOrder.getProjectPhaseList(this.project);
      });
    },
  },
};
</script>

<style lang="less" scoped>
.order-management {
  display: flex;
  padding: 2%;
  .order-view {
    width: calc(100% / 3);
  }
  .back {
    color: #fff;
  }
}
</style>
